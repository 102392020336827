import css from "./style.css";
import './style.css'
import "./style.scss";
import gsap from 'gsap'
var lang = document.querySelector(".lang-selector");
var langC = "fr"
var header = document.querySelector('.header');

lang.addEventListener("click", function() {
  if (langC == "fr") {
    lang.classList.add("lEN");
    langC = "en";
    const langFR = document.querySelectorAll(".fr");
    for (let i = 0; i < langFR.length; i++) {
      langFR[i].style.display = "none";
    }
    const langEN = document.querySelectorAll(".en");
    for (let i = 0; i < langEN.length; i++) {
      langEN[i].style.display = "block";
    }
  }else if (langC == "en") {
    lang.classList.remove("lEN");
    langC = "fr";
    const langEN = document.querySelectorAll(".en");
    for (let i = 0; i < langEN.length; i++) {
      langEN[i].style.display = "none";
    }
    const langFR = document.querySelectorAll(".fr");
    for (let i = 0; i < langFR.length; i++) {
      langFR[i].style.display = "block";
    }
  }
});


var choixStyle = "";
document.addEventListener("mousemove", function(event) {
    const x = event.pageX - 10;
    const y = event.pageY - 10;
    const cursor = document.querySelector("#cursor");
    cursor.style.left = x + "px";
    cursor.style.top = y + "px";
  });

  $('.item-arrow').on("mouseover", function(){
    $('#cursor').css({'transform': 'scale(2.5)'});
  });
  
  $('.item-arrow').on("mouseleave", function(){
    $('#cursor').css({'transform': 'scale(1)'});
  });
  
  $('.intensity-p').on("mouseover", function(){
    $('#cursor').css({'transform': 'scale(2.5)'});
  });
  
  $('.intensity-p').on("mouseleave", function(){
    $('#cursor').css({'transform': 'scale(1)'});
  });

  $('.sumbit').on("mouseover", function(){
    $('#cursor').css({'transform': 'scale(2.5)'});
  });
  
  $('.sumbit').on("mouseleave", function(){
    $('#cursor').css({'transform': 'scale(1)'});
  });

Splitting();

const options = {
  root: null, // use the document's viewport as the container
  rootMargin: "0px", // % or px - offsets added to each side of the intersection
  threshold: 0.2
};

const itemDelay = 0.1;

let fadeupCallback = (entries, self) => {
  let itemLoad = 0;
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const tl = gsap.timeline({ defaults: { ease: "power3.out" } });
      tl.set(entry.target, { visibility: "visible" });
      tl.from(entry.target, {
        duration: 1.5,
        y: 200,
        skewY: 40,
        autoAlpha: 0,
        delay: itemLoad * itemDelay,
        ease: "power3.out"
      });
      itemLoad++;
      self.unobserve(entry.target);
    }
  });
};

let fadeupObserver = new IntersectionObserver(fadeupCallback, options);

document.querySelectorAll("h1 span").forEach((fadeup) => {
  fadeupObserver.observe(fadeup);
});

var bUp = document.querySelector('.item-arrow');
var sheet1 = document.querySelector('.sheet-1')
var sheet2 = document.querySelector('.sheet-2')
var sheet3 = document.querySelector('.sheet-3')
var sheet4 = document.querySelector('.sheet-4')
var sheet5 = document.querySelector('.sheet-5')
var count = document.querySelector('.count')


bUp.addEventListener("click", function() {
    count.innerHTML = "2/5";
    header.classList.add('p2');
    sheet1.classList.add('hide')
    sheet2.classList.add('sheetUp')
    sheet2.style.display = "block";
});

var eye1 = document.querySelector('.eye-1');
var eye2 = document.querySelector('.eye-2');
var eye3 = document.querySelector('.eye-3');
var containerInt = document.querySelector('.intensity-container');

eye1.addEventListener("click", function() {
  count.innerHTML = "3/5";
  header.classList.add('p3');
  sheet2.classList.add('hide2')
  sheet3.classList.add('sheetUp2')
  sheet3.style.display = "block";
  containerInt.classList.add('show')
  choixStyle = 'foxy';
});
eye2.addEventListener("click", function() {
  count.innerHTML = "3/5";
  header.classList.add('p3');
  sheet2.classList.add('hide2')
  sheet3.classList.add('sheetUp2')
  sheet3.style.display = "block";
  containerInt.classList.add('show')
  choixStyle = 'round';
});
eye3.addEventListener("click", function() {
  count.innerHTML = "3/5";
  header.classList.add('p3');
  sheet2.classList.add('hide2')
  sheet3.classList.add('sheetUp2')
  sheet3.style.display = "block";
  containerInt.classList.add('show')
  choixStyle = 'inbetween';
});

let tab1 = ['CHARLY'];
let tab2 = ['POPPY'];
let tab3 = ['MIMY'];
let tab4 = ['ALY', 'BETTY', 'POLLY'];
let tab5 = ['DEMY', 'JODY', 'SALLY', 'SISSY'];
let tab6 = ['EMY'];
let tab7 = ['EVY', 'KIMY', 'ORY'];
let tab8 = ['CALY', 'ROMY'];
let tab9 = ['HEIDY', 'LALY', 'LEXY'];
let tabE1 = ['ALY', 'BETTY', 'CHARLY', 'EVY'];
let tabE2 = ['CALY', 'DEMY', 'JODY', 'POPPY'];
let tabE3 = ['LEXY', 'EMY', 'HEIDY', 'LALY'];

var template;

function choixProduit(key){
  console.log("ChoixProduit");
  var tabNew;
  if(choixStyle == 'foxy'){
    switch (key) {
      case 1:
        tabNew = tab1;
        template = 'pausy_fr1';
        break;
      case 2:
        tabNew = tab4;
        template = 'pausy_fr4';
        break;
      case 3:
        tabNew = tab7;
        template = 'pausy_fr7';
        break;
      default: tabNew = tab1;
        break;
    }
  }else if(choixStyle == 'round'){
    switch (key) {
      case 1:
        tabNew = tab2;
        template = 'pausy_fr2';
        break;
      case 2:
        tabNew = tab5;
        template = 'pausy_fr5';
        break;
      case 3:
        tabNew = tab8;
        template = 'pausy_fr8';
        break;
      default: tabNew = tab1;
        break;
    }
  }else if(choixStyle == 'inbetween'){
    switch (key) {
      case 1:
        tabNew = tab3;
        template = 'pausy_fr3';
        break;
      case 2:
        tabNew = tab6;
        template = 'pausy_fr6';
        break;
      case 3:
        tabNew = tab9;
        template = 'pausy_fr9';
        break;
      default: tabNew = tab1;
        break;
    }
  }
  return tabNew;
}

function getPictures(tabNew){
  console.log("rentre pictures")
  let img1 = document.querySelector(".ex-1");
  let img2 = document.querySelector(".ex-2");
  let img3 = document.querySelector(".ex-3");
  let img4 = document.querySelector(".ex-4");

  for (let index = 1; index < tabNew.length + 1; index++) {
    document.querySelector('.ex-'+index).style.backgroundImage = "url('https://antoinegenon.dev/testimg/"+tabNew[index - 1]+".webp')";
    console.log(document.querySelector('.ex-'+index))
    console.log(index);
  }
}

function getEPictures(tabNew){
  console.log("rentre pictures")
  for (let index = 1; index < tabNew.length + 1; index++) {
    document.querySelector('.exe-'+index).style.backgroundImage = "url('https://antoinegenon.dev/testimg/"+tabNew[index - 1]+".webp')";
    console.log(document.querySelector('.ex-'+index))
    console.log(index);
  }
}

function clearPictures(){
  for (let index = 1; index < 5; index++) {
    document.querySelector('.ex-'+index).style.backgroundImage = "none";
  }
}

function clearEPictures(){
  for (let index = 1; index < 5; index++) {
    document.querySelector('.exe-'+index).style.backgroundImage = "none";
  }
}

$('.intensity-1').on("mouseover", function(){
  clearPictures()
  console.log("mouseover")
  var tabNew = choixProduit(1);
  console.log(tabNew);
  getPictures(tabNew);
});
$('.intensity-2').on("mouseover", function(){
  clearPictures()
  console.log("mouseover")
  var tabNew = choixProduit(2);
  console.log(tabNew);
  getPictures(tabNew);
});
$('.intensity-3').on("mouseover", function(){
  clearPictures()
  console.log("mouseover")
  var tabNew = choixProduit(3);
  console.log(tabNew);
  getPictures(tabNew);
});

$('.intensity-1').on("mouseleave", function(){
  clearPictures()
});
$('.intensity-2').on("mouseleave", function(){
  clearPictures()
});
$('.intensity-3').on("mouseleave", function(){
  clearPictures()
});

var int1 = document.querySelector('.intensity-1');
var int2 = document.querySelector('.intensity-2');
var int3 = document.querySelector('.intensity-3');
var imgbg = document.querySelector('.img-bg');
var form = document.querySelector('.form');

var keyInt = 0;
int1.addEventListener("click", function() {
  count.innerHTML = "4/5";
  header.classList.add('p4');
  console.log("click");
  keyInt = 1;
  sheet3.classList.add('hide3')
  sheet4.classList.add('sheetUp3')
  sheet4.style.display = "block";
  imgbg.classList.add('show')
  form.classList.add('show')
  
})
int2.addEventListener("click", function() {
  count.innerHTML = "4/5";
  header.classList.add('p4');
  keyInt = 2;
  sheet3.classList.add('hide3')
  sheet4.classList.add('sheetUp3')
  sheet4.style.display = "block";
  imgbg.classList.add('show')
  form.classList.add('show')

})
int3.addEventListener("click", function() {
  count.innerHTML = "4/5";
  header.classList.add('p4');
  keyInt = 3;
  sheet3.classList.add('hide3')
  sheet4.classList.add('sheetUp3')
  sheet4.style.display = "block";
  imgbg.classList.add('show')
  form.classList.add('show')

})

document.getElementById('contact-form').addEventListener('submit', function(event) {
  event.preventDefault();
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;
  count.innerHTML = "5/5";
  header.classList.add('p5');
  sheet4.classList.add('hide4')
  sheet5.classList.add('sheetUp4')
  sheet5.style.display = "block";
  let rescontainer = document.querySelector('.res-container');
  rescontainer.classList.add('show')
  var tabNew = choixProduit(keyInt);
  getResults(tabNew);
  const serviceID = 'pausy_mail';
  console.log(this)
  console.log(template)
  emailjs.sendForm(serviceID, template, this)
      .then(function() {
          console.log('SUCCESS!');
      }, function(error) {
          console.log('FAILED...', error);
  });

});

function getResults(tabNew){
  console.log("rentre resultts")
  let res1 = document.querySelector(".res-1");
  let res2 = document.querySelector(".res-2");
  let res3 = document.querySelector(".res-3");
  let res4 = document.querySelector(".res-4");
  let ar1 = document.querySelector(".ar-1");
  let ar2 = document.querySelector(".ar-2");
  let ar3 = document.querySelector(".ar-3");
  let ar4 = document.querySelector(".ar-4");

  for (let index = 1; index < tabNew.length + 1; index++) {
    document.querySelector('.res-'+index).style.backgroundImage = "url('https://antoinegenon.dev/testimg/"+tabNew[index - 1]+".webp')";
    document.querySelector('.ar-'+index).href = "https://pausy-beauty.com/products/"+tabNew[index - 1];
    $('.res-'+index).attr('data-content',tabNew[index - 1]);
    console.log(document.querySelector('.res-'+index))
    console.log(index);
  }
}

$('#email').on('focus', function() {
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;
});

